/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@import 'theme';
@import 'material';
@import 'normalize';
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@700&display=swap');
@import '~swiper/css';
@import '~swiper/css/pagination';

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// 모바일 앱의 경우
.mobile-app {
  * {
    // 링크 롱 터치 불가
    -webkit-touch-callout: none;
  }
}

.loading-spinner-wrapper {
  background: rgba(0, 0, 0, 0.08);
}

// 크롬 스크롤 복원용 래퍼
main[wrapper] {
  min-height: 100vh;
  background-color: #fff;
}

// ios safearea top, bottom, left, right
[safearea] {
  @extend [safe-top];
  @extend [safe-bottom];
  @extend [safe-side];
}

// ios safearea top
[safe-top] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-top: constant(safe-area-inset-top);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

// ios safearea bottom
[safe-bottom] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-bottom: constant(safe-area-inset-bottom);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

// ios safearea left, right
[safe-side] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

[empty-list] {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
  color: #000000;
}

[loading-wrapper] {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

button[round] {
  border-radius: 99px;
  padding-right: 24px;
  padding-left: 24px;
}

[separator] {
  border-top: 1px solid $separator-color;
  margin: 8px 0;
  width: 100%;
}

[spacer] {
  width: 100%;
  height: 8px;
  background-color: $space-color;
}

[no-margin] {
  margin: 0;
}

::-webkit-scrollbar {
  width: 0px;
}

.mat-typography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
}

#ifrWrapper {
  width: 100%;
  height: 100%;

  #ifr {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.dialog-panel {
  max-width: 80vw;

  .mat-dialog-container {
    border-radius: 16px;
  }
}

.dialog-backdrop {
  background-color: transparent;
}

.fullscreen-modal {
  width: 100vw;
  height: 100vh;

  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }
}

.swiper-pagination-fraction {
  padding: 0 8px;
  left: initial;
  right: 16px;
  bottom: 6px;
  width: 40px;
  height: 18px;
  border-radius: 15px;
  opacity: 0.7;
  background-color: #000;
  line-height: 18px;
  font-size: 10px;
  color: #fff;
}

app-goods-info {
  .mat-expansion-panel-header {
    padding: 16px;
  }
  .mat-expansion-panel-body {
    padding: 0 16px 16px;
  }
  mat-expansion-panel.mat-expansion-panel:not([class*='mat-elevation-z']) {
    @include mat.elevation(1);
  }

  mat-card-header.mat-card-header {
    .mat-card-header-text {
      margin: 0;
    }
    mat-card-title.mat-card-title {
      margin: 0;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  mat-card-content.mat-card-content,
  .mat-expansion-panel-body {
    font-size: 12px;
    color: $font-color-light;
  }
  mat-panel-title {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }
}

app-profile {
  mat-slide-toggle {
    width: 100%;
    .mat-slide-toggle-label {
      justify-content: space-between;
    }
  }
}

mat-tab-group {
  $height: 34px;

  mat-tab-header.mat-tab-header {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 2;
  }

  .mat-tab-labels {
    .mat-tab-label {
      height: $height;
      padding: 0 20px;
    }
  }

  .mat-tab-body-wrapper {
    padding-top: $height;
  }
}

.mat-checkbox {
  color: #464646;
}

.mat-radio {
  color: #464646;
}

.mat-checkbox-checked {
  font-weight: bold;
  color: mat.get-color-from-palette($primary, 500);
}

.mat-radio-checked {
  font-weight: bold;
  color: mat.get-color-from-palette($primary, 500);
}

app-goods-opt-list {
  .goods-opt-wrapper {
    border-top: 1px solid $separator-color;
  }

  app-goods-opt {
    .goods-opt-desc {
      color: $font-color-lighter;

      &.opt-error {
        color: mat.get-color-from-palette(mat.$red-palette, 500);
      }
    }
    .mat-radio-button {
      width: 100%;
      .mat-radio-label {
        width: 100%;
        .mat-radio-container {
          margin-bottom: auto;
        }
        .mat-radio-label-content {
          width: 100%;
        }
      }
    }
    .mat-checkbox-layout {
      width: 100%;
      .mat-checkbox-inner-container {
        margin-left: 0;
        margin-top: 4px;
      }
      .mat-checkbox-label {
        width: 100%;
      }
    }
  }
}

app-address,
app-address-finder {
  .mat-form-field-outline {
    top: 0 !important;
  }

  .mat-form-field-infix {
    border-top: 0 !important;
  }
}

app-profile {
  .mat-form-field-infix {
    width: auto;
  }
}

qrcode {
  .qrcode {
    display: flex;
  }
}

app-bbs-detail .bbs_detail {
  p {
    margin: 0;
  }
  li {
    all: revert;
  }
  ul {
    all: revert;
  }
  ol {
    all: revert;
  }
}

.padding-left-27px {
  padding-left: 27px;
}
